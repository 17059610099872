import storage from "redux-persist/lib/storage";

// ROOT
export const persistConfig = {
   key: "root",
   storage: storage,
};

// AUTH
export const authPersistConfig = {
   key: "auth",
   storage: storage,
};
