import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../root-reducer";

export interface CurrentUserType {
   id: string;
   first_name: string;
   last_name: string;
   email: string;
   password: string;
   gender: string;
}

export interface AuthState {
   token?: string;
   currentUser?: CurrentUserType;
   isLoading?: boolean;
   error?: string;
}

export const initialState: AuthState = {
   token: "",
   isLoading: false,
   error: "",
};

export const authSlice = createSlice({
   name: "auth",
   initialState,
   reducers: {
      loginStart: (state) => {
         state.isLoading = true;
      },
      loginSuccess: (state, { payload }: PayloadAction<AuthState>) => {
         state.currentUser = payload.currentUser;
         state.token = payload.token;
         state.isLoading = false;
      },
      loginFailed: (state, { payload }: PayloadAction<string>) => {
         state.error = payload;
         state.isLoading = false;
      },
      logOut: (state) => {
         window.localStorage.clear();
         window.sessionStorage.clear();
         return { ...initialState };
      },
   },
});

export const { loginStart, loginSuccess, loginFailed, logOut } =
   authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;
