import React from "react";
import classNames from "classnames";

// MATERIAL UI CORE COMPONENTS
import Grid from "@material-ui/core/Grid";

type GridItemProps = {
   children: any;
   relative?: string;
   className?: any;
   xs?: any;
   sm?: any;
   md?: any;
   lg?: any;
   rest?: object;
};

const GridItem = ({
   children,
   relative,
   className,
   xs,
   sm,
   md,
   lg,
   ...rest
}: GridItemProps) => {
   const gridClasses = classNames({
      [className]: className,
      "u-relative": !!relative,
   });

   return (
      <Grid
         item
         {...rest}
         xs={xs}
         sm={sm}
         md={md}
         lg={lg}
         className={gridClasses}>
         {children}
      </Grid>
   );
};

export default GridItem;
