import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";

// SLICES
import { authSlice } from "./auth/auth.slice";
import { commonSlice } from "./common/common.slice";
import { statisticsSlice } from "./statistics/statistics.slice";

// PERSIST CONFIG
import { persistConfig, authPersistConfig } from "./persist-config";

const rootReducer = combineReducers({
   auth: persistReducer(authPersistConfig, authSlice.reducer),
   common: commonSlice.reducer,
   statistics: statisticsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);
