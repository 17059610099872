import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../root-reducer";

// Define a type for the slice state
export interface GlobalMessageState {
   severity: string;
   message: string;
}

// Define the initial state using that type
const initialState: GlobalMessageState = {
   severity: "success",
   message: "",
};

export const commonSlice = createSlice({
   name: "common",
   initialState,
   reducers: {
      setGlobalMessageReducer: (state, { payload }: PayloadAction<any>) => {
         state.severity = payload.severity;
         state.message = payload.message;
      },
   },
});

export const { setGlobalMessageReducer } = commonSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCommon = (state: RootState) => state.common;

export default commonSlice.reducer;
