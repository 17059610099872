import React from "react";

// MATERIAL UI COMPONENTS
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

type NotificationProps = {
   isOpened?: boolean;
   message: string;
   delay?: number;
   severity?: any;
   handleNotificationClose?: () => void;
};

const Notification = ({
   isOpened,
   message,
   delay,
   severity,
   handleNotificationClose,
}: NotificationProps) => {
   return (
      <Snackbar
         className="notification"
         open={isOpened}
         anchorOrigin={{
            vertical: "top",
            horizontal: "center",
         }}
         autoHideDuration={delay}
         onClose={handleNotificationClose}>
         <Alert
            onClose={handleNotificationClose}
            severity={severity}
            className="message">
            {message}
         </Alert>
      </Snackbar>
   );
};

export default Notification;
