import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// BASE COMPONENTS
import GridItem from "components/grid-item/GridItem.component";
import Input from "components/input/Input.component";
import Button from "components/button/Button.component";

// EFFECTS
import useInput from "effects/useInput.effect";
// ACTIONS
import { loginAsync } from "redux/auth/auth.actions";
// SELECTORS
import { selectAuth } from "redux/auth/auth.slice";

const LoginPage = () => {
   const authStore = useSelector(selectAuth);
   const dispatch = useDispatch();

   const history = useHistory();

   const [formState, setFormState] = useState({
      email: "",
      password: "",
   });

   const { handleInput, handleInvalidMessage, invalidMessages } = useInput({
      ...formState,
   });

   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      handleInput(event);
      setFormState({
         ...formState,
         [event.target.name]: event.target.value,
      });
   };

   const login = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      dispatch(
         loginAsync(
            { email: formState.email, password: formState.password },
            history
         )
      );
   };

   return (
      <section className="login-page">
         <div className="login-page__auth-block">
            <GridItem xs={12} sm={12} md={4} lg={4}>
               <div className="login-page__auth-block-card">
                  <GridItem
                     xs={12}
                     sm={12}
                     md={12}
                     lg={12}
                     className="fields__block">
                     <form onSubmit={login}>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                           <Input
                              name="email"
                              value={formState?.email}
                              error={invalidMessages}
                              onChange={handleInputChange}
                              onInvalid={handleInvalidMessage}
                              autoComplete="on"
                              label="E-mail"
                              type="text"
                              required
                           />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                           <Input
                              name="password"
                              value={formState?.password}
                              error={invalidMessages}
                              onChange={handleInputChange}
                              onInvalid={handleInvalidMessage}
                              label="Password"
                              type="password"
                              required
                           />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                           <Button
                              isLoading={authStore.isLoading}
                              type="submit">
                              Login
                           </Button>
                        </GridItem>
                     </form>
                  </GridItem>
               </div>
            </GridItem>
         </div>
      </section>
   );
};

export default LoginPage;
