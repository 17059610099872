import React from "react";
import classNames from "classnames";

type InputProps = {
   className?: any;
   variant?: any;
   inputData?: any;
   value?: any;
   name?: string;
   onChange?: React.FormEventHandler<HTMLInputElement>;
   error?: object;
   onInvalid?: (event: any, newMsg: string) => void;
   label?: string;
   icon?: string;
   otherProps?: object;
   type?: string;
   required?: boolean;
   autoComplete?: any;
};

const Input = (props: InputProps) => {
   const { name = "" } = props;

   const {
      className,
      variant,
      inputData,
      value = inputData?.inputState[name],
      onChange = inputData?.handleInput,
      error = inputData?.invalidMessages,
      onInvalid = inputData?.handleInvalidMessage,
      label,
      icon,
      required,
      autoComplete,
      ...otherProps
   } = props;

   const inputClasses = classNames({
      "li-input": true,
      [className]: !!className,
   });

   const nativeInputClasses = classNames({
      "li-input__native": true,
      "li-header": variant === "header",
      "li-card-header": variant === "card-header",
      "li-login": variant === "login",
   });

   const labelClasses = classNames({
      "li-input__label": true,
   });

   const errorMessageBlockClasses = classNames({
      "li-input__error": true,
      "li-input__error-with-label": label !== undefined,
   });

   const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event);
   };

   const handleSelect = (event: any) => {
      if (error) onInvalid(event, "");
   };

   return (
      <div className={inputClasses}>
         {label && <div className={labelClasses}>{label}</div>}
         <div>
            <input
               className={nativeInputClasses}
               value={value || ""}
               onChange={handleChange}
               onInvalid={onInvalid}
               onSelect={handleSelect}
               required={required}
               autoComplete={autoComplete}
               {...otherProps}
            />
            {icon && <img src={icon} alt="icon" />}
         </div>
         {!!error?.[name] && (
            <p className={errorMessageBlockClasses}>{error?.[name] || ""}</p>
         )}
      </div>
   );
};

export default Input;
