import React from "react";
import classNames from "classnames";

// MATERIAL UI COMPONENTS
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

type ButtonProps = {
   children: any;
   className?: any;
   isLoading?: boolean;
   disabled?: boolean;
   src?: string;
   color?: any;
   variant?: any;
   otherOptions?: object;
   type?: any;
};

const CustomButton = ({
   children,
   className,
   isLoading,
   disabled,
   src,
   color = "primary",
   variant = "contained",
   type,
   ...otherProps
}: ButtonProps) => {
   const btnClasses = classNames({
      "li-button": true,
      "li-button__disabled": disabled,
      [className]: className,
   });

   return (
      <Button
         className={btnClasses}
         color={color}
         variant={variant}
         type={type}
         {...otherProps}
         disabled={disabled || isLoading}>
         {src && <img src={src} alt="icon" />}
         <span>{children}</span>
         {isLoading && (
            <div className="spinner">
               <CircularProgress color="secondary" />
            </div>
         )}
      </Button>
   );
};

export default CustomButton;
