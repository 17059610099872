import authApi from "./auth.api";
import {
   loginStart,
   loginSuccess,
   loginFailed,
   logOut,
   CurrentUserType,
} from "./auth.slice";
import { AppThunk } from "../store";
//ACTIONS
import { setGlobalMessage } from "redux/common/common.actions";

interface loginPropsInterface {
   email: string;
   password: string;
}

export const loginAsync =
   ({ email, password }: loginPropsInterface, history: any): AppThunk =>
   async (dispatch) => {
      try {
         dispatch(loginStart());

         const resp = await authApi.login();
         const users = resp?.data?.users || [];
         const loggedUser = users.filter(
            (user: CurrentUserType) =>
               user.email === email && user.password === password
         )[0];

         if (loggedUser) {
            dispatch(
               loginSuccess({
                  currentUser: loggedUser,
                  token: loggedUser.token,
               })
            );
            dispatch(
               setGlobalMessage({
                  severity: "success",
                  message: "You are successfully logged in!",
               })
            );
            history.push("/statistics");
         } else {
            dispatch(
               setGlobalMessage({
                  severity: "error",
                  message: "Wrong email or password.",
               })
            );
            dispatch(loginFailed("Wrong email or password."));
         }
      } catch (error) {
         dispatch(loginFailed(error.message));
         dispatch(
            setGlobalMessage({
               severity: "error",
               message: error.message,
            })
         );
      }
   };

export const logOutAsync = (): AppThunk => async (dispatch) => {
   dispatch(logOut());
};
