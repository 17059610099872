import React from "react";
import { useSelector, useDispatch } from "react-redux";

// BASE COMPONENTS
import Notification from "components/notification/Notification.component";
// ROUTES
import AuthRoutes from "pages/auth/AuthPages";
import DashbaordRoutes from "pages/dashboard/DashboardPages";
// SELECTORS
import { selectAuth } from "redux/auth/auth.slice";
import { selectCommon } from "redux/common/common.slice";
// ACTIONS
import { setGlobalMessage } from "redux/common/common.actions";

function App() {
   const dispatch = useDispatch();
   const authStore = useSelector(selectAuth);
   const commonStore = useSelector(selectCommon);

   const View = authStore?.token ? <DashbaordRoutes /> : <AuthRoutes />;

   const handleNotificationClose = () => {
      dispatch(
         setGlobalMessage({
            severity: commonStore.severity,
            message: "",
         })
      );
   };

   return (
      <div className="App">
         <Notification
            message={commonStore.message}
            isOpened={!!commonStore.message}
            delay={7000}
            severity={commonStore.severity}
            handleNotificationClose={handleNotificationClose}
         />
         {View}
      </div>
   );
}

export default App;
