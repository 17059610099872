import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../root-reducer";

interface HistoryLogProps {
   name: string;
   value: string;
}
// Define a type for the slice state
export interface HistoryLogState {
   historyLog: HistoryLogProps[];
}

// Define the initial state using that type
const initialState: HistoryLogState = {
   historyLog: [],
};

export const statisticsSlice = createSlice({
   name: "statistics",
   initialState,
   reducers: {
      updateHistoryLogReducer: (state, { payload }: PayloadAction<any>) => {
         const { name, value } = payload;

         if (state.historyLog.length >= 10) {
            state.historyLog.shift();
         }

         state.historyLog = [...state.historyLog, { name, value }];
      },
      resetHistoryLogReducer: (state) => {
         state.historyLog = [];
      },
   },
});

export const { updateHistoryLogReducer, resetHistoryLogReducer } =
   statisticsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectStatistics = (state: RootState) => state.statistics;

export default statisticsSlice.reducer;
