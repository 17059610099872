import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// SLICES
import { selectAuth } from "redux/auth/auth.slice";

// ACTIONS
import { logOutAsync } from "redux/auth/auth.actions";
import { resetHistoryLog } from "redux/statistics/statistics.actions";

const Header = () => {
   const authStore = useSelector(selectAuth);
   const dispatch = useDispatch();
   const history = useHistory();

   const onLogOut = () => {
      dispatch(logOutAsync());
      history.push("/login");
      dispatch(resetHistoryLog());
   };

   return (
      <header>
         <span>{authStore?.currentUser?.email}</span>
         <span onClick={onLogOut} className="log-out__button">
            Log Out
         </span>
      </header>
   );
};

export default Header;
