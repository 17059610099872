import { configureStore, Action, getDefaultMiddleware } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { ThunkAction } from "redux-thunk";
import logger from "redux-logger";
import {
   persistStore,
   REGISTER,
   FLUSH,
   REHYDRATE,
   PAUSE,
   PERSIST,
   PURGE,
} from "redux-persist";

// ROOT REDUCER
import rootReducer, { RootState } from "./root-reducer";

// ADD OTHER MIDDLEWARES
const middleware = [
   ...getDefaultMiddleware({
      serializableCheck: {
         ignoredActions: [REGISTER, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE],
      },
   }),
   logger,
];

// CONFIGURE STORE
const store = configureStore({
   reducer: rootReducer,
   middleware,
   devTools: process.env.NODE_ENV === "development",
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
export const persistor = persistStore(store);

export default store;
