import { GlobalMessageState, setGlobalMessageReducer } from "./common.slice";
import { AppThunk } from "../store";

export const setGlobalMessage =
   (messageObject: GlobalMessageState): AppThunk =>
   (dispatch) => {
      dispatch(
         setGlobalMessageReducer({
            severity: messageObject.severity,
            message: messageObject.message,
         })
      );
   };
