import React from "react";

// BASE COMPONENTS
import Input from "components/input/Input.component";
import IsVisible from "components/is-visible/IsVisible.component";

type TableProps = {
   tableHead?: any;
   tableBody?: any;
   formOptions?: any;
   editable?: boolean;
   rest?: any;
   series?: any;
};

const Table = ({
   tableHead,
   tableBody,
   formOptions = {},
   editable = true,
   series,
}: TableProps) => {
   const {
      inputState,
      invalidMessages,
      handleInputChange,
      handleInvalidMessage,
   } = formOptions;

   return (
      <table>
         {tableHead && (
            <thead>
               <tr>
                  <th />
                  {tableHead.map((item: any, headIdx: number) => (
                     <th key={headIdx}>{item}</th>
                  ))}
               </tr>
            </thead>
         )}
         {tableBody && (
            <tbody>
               {tableBody.map((rows: any, rowsIdx: number) => (
                  <tr key={rowsIdx}>
                     <td>{series[rowsIdx].name}</td>
                     {rows.map((item: any) => (
                        <td key={item.id}>
                           <IsVisible isVisible={editable}>
                              <Input
                                 name={item.name}
                                 value={inputState[item.name]}
                                 error={invalidMessages}
                                 onChange={handleInputChange}
                                 onInvalid={handleInvalidMessage}
                                 type="number"
                              />
                           </IsVisible>
                           <IsVisible isVisible={!editable}>
                              <span>{item?.value}</span>
                           </IsVisible>
                        </td>
                     ))}
                  </tr>
               ))}
            </tbody>
         )}
      </table>
   );
};

export default Table;
