import {
   updateHistoryLogReducer,
   resetHistoryLogReducer,
} from "./statistics.slice";
import { AppThunk } from "../store";

export const updateHistoryLog =
   (historyLog: { name: string; value: string }): AppThunk =>
   (dispatch) => {
      dispatch(updateHistoryLogReducer(historyLog));
   };

export const resetHistoryLog = (): AppThunk => (dispatch) => {
   dispatch(resetHistoryLogReducer());
};
